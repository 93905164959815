import React from 'react';
import PropTypes from 'prop-types';
import PackageGroup from '../../PackageGroup';

const ProductCards = ({ groups }) => (
  <>
    {groups.map((packageGroup) => (
      <PackageGroup
        key={packageGroup.subject}
        subject={packageGroup.subject}
        packages={packageGroup.packages}
      />
    ))}
  </>
);

ProductCards.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.string.isRequired,
      packages: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string.isRequired,
          image: PropTypes.object.isRequired,
        }),
      ),
    }),
  ),
};

export default ProductCards;
