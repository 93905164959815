import styled from '@emotion/styled';
import Link from '../../UI/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';
import Button from '../Button';

const StyledButton = styled(Button)`
  font-weight: 400;
  font-size: 14px;
  padding: 6px 20px;
  ${EMOTION_BREAKPOINTS.sm} {
    padding: 14px 20px;
    font-size: 21px;
  }
`;

const defaults = {
  label: 'Available on App Store',
  link: 'https://apps.apple.com/us/app/classed-up/id1153433683',
};

export const DownloadAppButton = ({
  label = defaults.label,
  link = defaults.link,
}) => (
  <Link to={link}>
    <StyledButton color="blue">{label}</StyledButton>
  </Link>
);

DownloadAppButton.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
};
