import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';
import { DownloadAppButton } from '../../UI/DownloadAppButton';

const Section = styled.section`
  padding: 0 30px;
  position: relative;
  align-items: center;
  h1 {
    font-size: ${({ theme }) => theme.fontSizes[6]};
    font-weight: 400;
    margin-bottom: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.colors.text};
  }
  display: flex;
  flex-direction: column-reverse;
  ${EMOTION_BREAKPOINTS.sm} {
    flex-direction: row;
    justify-content: space-between;
    h1 {
      margin-bottom: ${({ theme }) => theme.spacing[1]};
    }
  }
`;

const Description = styled.div`
  margin: 30px 0 50px;
  width: 100%;
  p {
    font-size: 21px;
    color: ${({ theme }) => theme.colors.textGrey};
    font-weight: 400;
    margin: 0 0 30px;
  }

  ${EMOTION_BREAKPOINTS.sm} {
    margin: 30px 0 80px;
    width: 80%;
  }
`;

const TextContainer = styled.div`
  flex-basis: ${({ theme }) => theme.width(6)};

  h1 {
    margin-top: 30px;

    ${EMOTION_BREAKPOINTS.sm} {
      margin-top: 20px;
    }
  }
  
  ${EMOTION_BREAKPOINTS.sm} {
    padding-right: ${({ theme }) => theme.spacing[4]};
  }
`;

const Masthead = () => (
  <Section>
    <TextContainer>
      <h1>Music Theory Practice Questions</h1>
      <Description>
        <p>Learning music theory? Use our music theory quizzes and 
          exercises to help reinforce the concepts you learn in class.
        </p>
        <p>
          If you teach the Royal Conservatory of Music (RCM) or the Faber music 
          program, our RCM and Faber packages follow the official program syllabus.
        </p>
      </Description>
      <DownloadAppButton />
    </TextContainer>
    <div
      css={css`
        overflow: hidden;
        max-width: 326px;
        margin-right: 0;
        z-index: 3;
        ${EMOTION_BREAKPOINTS.sm} {
          flex: 0 0 526px;
          max-width: 526px;
        }
      `}
    >
      <StaticImage
        src="../../../images/home-hero.svg"
        alt="hero image"
        placeholder="blurred"
        width={526}
        quality={100}
      />
    </div>
  </Section>
);

export default Masthead;
