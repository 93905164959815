import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { borderRadiusSecondary } from '../../../styleTokens';

const variants = {
  outlined: 'outlined',
};

const StyledButton = styled.button`
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.9px;
  text-align: center;
  outline: none;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
  background-color: ${({ theme, color }) => {
    // does theme have color ?
    if (theme.colors[color]) return theme.colors[color];
    // default to white
    return '#fff';
  }};
  color: ${({ theme, color }) => {
    // does theme have color ?
    if (theme.colors[color]) return '#fff';
    // default to white
    return theme.colors.darkgrey;
  }};
  padding: 8px 12px;
  ${borderRadiusSecondary};
  :focus {
    outline: none;
  }
`;

const OutlinedButton = styled(StyledButton)`
  font-weight: 400;
  border: 1px solid
    ${({ theme, color }) => {
      // does theme have color ?
      if (theme.colors[color]) {
        return theme.colors[color];
      }
      return theme.colors.white;
    }};
  color: ${({ theme, color }) => {
    // does theme have color ?
    if (theme.colors[color]) return theme.colors[color];
    // default to white
    return '#fff';
  }};
  background-color: ${({ theme, color }) => {
    // does theme have color ?
    if (theme.colors[color]) return '#fff';
    // default to white
    return theme.colors.darkgrey;
  }};
`;

export const Button = ({ color, variant, children, ...rest }) =>
  variant === variants.outlined ? (
    <OutlinedButton color={color} {...rest}>
      {children}
    </OutlinedButton>
  ) : (
    <StyledButton color={color} {...rest}>
      {children}
    </StyledButton>
  );

Button.propTypes = {
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.keys(variants)),
};

export default Button;
