import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';
import AppStore from '../../../images/AppStore.svg';
import { StaticImage } from 'gatsby-plugin-image';
import theme from '../../../theme';

const Container = styled.section`
  display: flex;
  flex-flow: row wrap;
  ${EMOTION_BREAKPOINTS.sm} {
    justify-content: space-between;
    align-items: stretch;
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const CallToAction = styled.div`
  text-align: left;
  ${EMOTION_BREAKPOINTS.sm} {
    text-align: right;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  align-items: baseline;
  ${EMOTION_BREAKPOINTS.sm} {
    align-items: center;
  }

  p {
    font-size: 21px;
    color: ${({ theme }) => theme.colors.textGrey};
    font-weight: 400;
    margin: 0;
    padding: 20px 0 0;

    ${EMOTION_BREAKPOINTS.sm} {
      padding: 0%;
    }
  }
  a {
    :visited {
      color: ${({ theme }) => theme.colors.text};
    }
    outline: none;
  }
  .strong {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes[6]};
    font-weight: 400;

    ${EMOTION_BREAKPOINTS.sm} {
      font-size: ${({ theme }) => theme.fontSizes[8]};
      padding-bottom: 20px;
    }
  }

  margin: 0 30px;
  ${EMOTION_BREAKPOINTS.sm} {
    margin-left: ${({ theme }) => theme.spacing[3]};
    margin-right: 0px;
    align-items: flex-end;
  }
`;

const MobileDivider = styled.div`
  width: 100%;
  /* height: 20px; */

  ${EMOTION_BREAKPOINTS.sm} {
    display: none;
  }
`;

const AppStoreBanner = () => (
  <Container>
    <div>
      <StaticImage
        alt="ipad"
        src="../../../images/iPad.svg"
        css={css`
          display: none;
          ${EMOTION_BREAKPOINTS.sm} {
            display: block;
          }
        `}
      />
    </div>
    <MobileDivider />
    <CallToAction>
      <div>
          <div className="strong">
            Download the <span style={{ color: theme.colors.red }}>app</span>
          </div>
        <p>Available on iPad and all newer Macs</p>
      </div>

      <a href="https://apps.apple.com/us/app/classed-up/id1153433683">
        <img
          src={AppStore}
          alt="Download on the App Store"
          css={css`
            width: 150px;
            margin-bottom: 0;
            margin-top: 25px;
            ${EMOTION_BREAKPOINTS.sm} {
              margin-bottom: inherit;
            }
          `}
        />
      </a>
    </CallToAction>
  </Container>
);

export default AppStoreBanner;
