import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Card from '../UI/Card';
import { EMOTION_BREAKPOINTS } from '../../constants/breakpoints';

const Container = styled.div`
  margin: 0;

  ${EMOTION_BREAKPOINTS.sm} {
    margin: 0 30px;
  }
`;

const Subject = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes[5]};
  font-weight: 400;
  margin: 30px;

  ${EMOTION_BREAKPOINTS.sm} {
    margin: 0;
  }
`;

const PackagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${EMOTION_BREAKPOINTS.sm} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 80px;
    padding-bottom: 60px;
  }
`;

const PackageCard = styled(Card)`
  height: auto;
  width: 315px;
  border-radius: 25px;
  background-color: transparent;

  ${EMOTION_BREAKPOINTS.sm} {
    width: 300px;
  }
`;

const PackageGroup = ({ subject, packages }) => {
  if ((packages?.length ?? 0) === 0) {
    return null;
  }
  return (
    <Container>
      <Subject>{subject}</Subject>
      <PackagesContainer>
        {packages.map((p) => (
          <PackageCard key={p.link}>
            <Link key={p.link} to={p.link}>
              <GatsbyImage image={p.image} alt={p.link} />
            </Link>
          </PackageCard>
        ))}
      </PackagesContainer>
    </Container>
  );
};

PackageGroup.propTypes = {
  subject: PropTypes.string.isRequired,
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    }),
  ).isRequired,
};

export default PackageGroup;
