import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import React from 'react';
import AppStoreBanner from '../components/Home/AppStoreBanner';
import Masthead from '../components/Home/Masthead';
import ProductCards from '../components/Home/ProductCards';
import { Container } from '../components/UI';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { EMOTION_BREAKPOINTS } from '../constants/breakpoints';
import theme from '../theme';

const mastheadStyle = css`
  padding-top: 20px;
  padding-bottom: 50px;
  ${EMOTION_BREAKPOINTS.sm} {
    padding-top: 40px;
    padding-bottom: 100px;
  }
`;

const paddingStyle = css`
  padding-top: 20px;
  padding-bottom: 50px;
  ${EMOTION_BREAKPOINTS.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const appStorePaddingStyle = css`
  padding-top: 50px;
  padding-bottom: 20px;
  ${EMOTION_BREAKPOINTS.sm} {
    padding-top: 150px;
    padding-bottom: 40px;
  }
`;

const greyBg = css`
  background-color: ${theme.colors.lightgrey};
`;

function buildGroups(data) {
  const categories = data.allContentfulCategory.edges.map((edge) => edge.node);
  const packages = data.allContentfulPackage.edges.map((edge) => edge.node);

  packages.sort((a, b) => a.order - b.order);

  for (const category of categories) {
    category.packages = packages.filter((p) =>
      p.categories.some((c) => c.id === category.id),
    );
  }

  categories.sort((a, b) => a.order - b.order);

  const groups = categories.map((category) => ({
    subject: category.title,
    packages: category.packages.map((p) => ({
      link: `/packages/${p.slug}`,
      image: p.thumbnail.gatsbyImageData,
    })),
  }));

  return groups;
}

const HomePage = ({ data }) => {
  const groups = React.useMemo(() => buildGroups(data), [data]);
  return (
    <Layout>
      <Seo
        title="Home"
        keywords={[
          `music lessons app`,
          `educational app`,
          `interactive music appn`,
        ]}
      />
      <Container css={mastheadStyle}>
        <Masthead />
      </Container>
      <div css={greyBg}>
        <Container css={paddingStyle}>
          <ProductCards groups={groups} />
        </Container>
      </div>
      <Container css={appStorePaddingStyle}>
        <AppStoreBanner />
      </Container>
    </Layout>
  );
};

export const HomeQuery = graphql`
  query HomeQuery {
    allContentfulCategory {
      edges {
        node {
          id
          title
          order
        }
      }
    }

    allContentfulPackage {
      edges {
        node {
          id
          categories {
            id
          }
          slug
          title
          thumbnail {
            gatsbyImageData(width: 600)
          }
          order
        }
      }
    }
  }
`;

export default HomePage;
